import React from 'react';
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import saliou from '../assets/img/saliou.jpg';
import '../styles/Certifications.css';

const Certifications = () => {
  const certifications = [
    {
      name: "CompTIA Security+",
      provider: "CompTIA"
    },
    {
      name: "Microsoft Azure Fundamentals",
      provider: "Microsoft"
    },
    {
      name: "AWS Developer Associate",
      provider: "Amazon Web Services"
    }
  ];

  return (
    <section className="certifications-container" id="certifications">
      <div className="content-container">
        <h2 className="cert-heading">Certifications</h2>
        <ul className="cert-list">
          {certifications.map((cert, i) => (
            <li key={i} className="cert-item">
              <div className="cert-name">{cert.name}</div>
              <div className="cert-provider">{cert.provider}</div>
            </li>
          ))}
        </ul>
      </div>

      <TrackVisibility>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
            <div className="image-container">
              <img
                src={saliou}
                alt="saliou"
                className="profile-image"
              />
            </div>
          </div>
        }
      </TrackVisibility>
    </section>
  );
};

export default Certifications;