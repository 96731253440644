import React from 'react';
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import barry from '../assets/img/barry.jpg';
import '../styles/Education.css';

const Education = () => {
  const education = [
    {
      degree: "B.S., Computer Science & Systems",
      year: "2018",
      school: "UNIVERSITY OF WASHINGTON",
      location: "Tacoma, WA"
    },
    {
      degree: "A.A., Computer Science & Systems",
      year: "2015",
      school: "HIGHLINE COLLEGE",
      location: "Des Moines, WA"
    },
    {
      degree: "A.A., Computer Science & Systems",
      year: "2013",
      school: "PRINCE GEORGES COMMUNITY COLLEGE",
      location: "Largo, MD"
    }
  ];

  return (
    <section className="education-container" id="education">
      <div className="education-content">
        <h2 className="education-heading">Education</h2>
        <ul className="education-list">
          {education.map((edu, i) => (
            <li key={i} className="education-item">
              <h3 className="education-degree">{edu.degree}, {edu.year}</h3>
              <div className="education-school">{edu.school}</div>
              <div className="education-location">{edu.location}</div>
            </li>
          ))}
        </ul>
      </div>

      <TrackVisibility>
        {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__rotateIn" : ""}>
            <div className="edu-image-container">
              <img
                src={barry}
                alt="barry"
                className="edu-profile-image"
              />
            </div>
          </div>
        }
      </TrackVisibility>
    </section>
  );
};

export default Education;