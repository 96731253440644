import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import { Github, Instagram, Linkedin } from 'lucide-react';
import '../styles/NavBar.css';

const DynamicNavbarBrandLogo = ({
  initials = "MB",
  size = "20",
  defaultColor = "purple",
  hoverColor = "black",
  fontSize = "xl",
  animated = true
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [logoSize, setLogoSize] = useState(size);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setLogoSize("sm");
      } else if (width < 1024) {
        setLogoSize("md");
      } else {
        setLogoSize(size);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [size]);

  const getLogoClasses = () => {
    const sizeClass = `logo-size-${logoSize}`;
    const colorClass = isHovered ? `logo-color-${hoverColor}` : `logo-color-${defaultColor}`;
    const animationClass = animated ? 'logo-animated' : '';
    const hoverClass = isHovered ? 'logo-hovered' : '';

    return `logo-container ${sizeClass} ${colorClass} ${animationClass} ${hoverClass}`;
  };

  return (
    <Navbar.Brand href="/">
      <div
        className={getLogoClasses()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className={`logo-text logo-text-${fontSize}`}>
          {initials}
        </span>
      </div>
    </Navbar.Brand>
  );
};

const SocialIcons = () => {
  const [iconSize, setIconSize] = useState(24);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setIconSize(20);
      } else if (width < 1024) {
        setIconSize(22);
      } else {
        setIconSize(24);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="social-icon">
      <a href="https://www.linkedin.com/in/mamadousbarry" target="_blank" rel="noopener noreferrer" className="linkedin">
        <Linkedin size={iconSize} />
      </a>
      <a href="https://github.com/barrysaliou18" target="_blank" rel="noopener noreferrer" className="github">
        <Github size={iconSize} />
      </a>
      <a href="https://www.instagram.com/barrysaliou18/" target="_blank" rel="noopener noreferrer" className="instagram">
        <Instagram size={iconSize} />
      </a>
    </div>
  );
};

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <DynamicNavbarBrandLogo />
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {['home', 'skills', 'certifications', 'experience', 'education'].map((item) => (
                <Nav.Link
                  key={item}
                  href={`#${item}`}
                  className={activeLink === item ? 'active navbar-link' : 'navbar-link'}
                  onClick={() => onUpdateActiveLink(item)}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </Nav.Link>
              ))}
            </Nav>
            <span className="navbar-text">
              <SocialIcons />
              <HashLink to='#connect'>
                <button className="vvd"><span>Let's Connect</span></button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};