import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';

const StyledFooter = styled.footer`
  padding: 50px 0;
  background-color: #121212;
`;

const FooterText = styled.p`
  font-size: 16px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
  text-align: center;  // Changed from left to center
`;

const FooterCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;  // Changed from right to center
  justify-content: center;  // Changed from right to center
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Row>
          <FooterCol size={12} sm={12}>  {/* Changed sm={6} to sm={12} for full width */}
            <FooterText>Mamadou Barry © 2024. All Rights Reserved</FooterText>
          </FooterCol>
        </Row>
      </Container>
    </StyledFooter>
  );
};