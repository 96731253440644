import React, { useState, useEffect, useRef } from 'react';
import '../styles/ProfessionalExperience.css';

const ProfessionalExperience = () => {
  const [activeTabId, setActiveTabId] = useState(0);
  const [tabFocus, setTabFocus] = useState(null);
  const tabs = useRef([]);

  const experiences = [
    {
      company: "Museum of Flight",
      location: "Tukwila, WA",
      title: "IT System Engineer",
      range: "07/2024 to Present",
      responsibilities: [
        "Guided migration of 20 legacy servers to a virtual environment, cutting infrastructure costs by 40% and improving system performance and reliability by 50%.",
        "Upgraded 500 servers using Ansible, reducing deployment time by 30% and increasing efficiency by 20% through automated hardware and software updates.",
        "Created Ansible playbooks for Windows and Ubuntu updates, reducing manual tasks by 60%, enhancing security, and ensuring compliance with the latest patches.",
        "Implemented proactive monitoring, boosting system uptime by 25% and cutting incident response time by 35% with automated maintenance and updates using Ansible."
      ]
    },
    {
      company: "Rite Solutions Inc.",
      location: "Keyport, WA",
      title: "System Administrator",
      range: "03/2022 to 05/2024",
      responsibilities: [
        "Directed the management of system administration tasks encompassing a vast array of RHEL and Windows servers, ensuring continuous availability and service reliability.",
        "Implemented rigorous security protocols, fortifying the integrity and confidentiality of sensitive data within a high-security environment.",
        "Orchestrated the migration of Atlassian toolsets from on-premise servers to the Microsoft Azure cloud environment, optimizing scalability and cost-effectiveness.",
        "Led the migration of critical servers from Windows to Linux platforms, enhancing resource utilization and streamlining maintenance processes."
      ]
    },
    {
      company: "Infosys Limited",
      location: "Bellevue, WA",
      title: "System Administrator",
      range: "01/2021 to 03/2022",
      responsibilities: [
        "Managed the migration of Bitbucket server infrastructure from Windows to the RHEL platform, ensuring minimal disruption to ongoing operations.",
        "Led the upgrade of Bitbucket, implementing robust security measures and performance enhancements.",
        "Executed the migration of TeamworkCloud, Cassandra, and Flexnet servers from Windows to Linux platforms, conducting risk assessments and contingency planning.",
        "Leveraged orchestration utilities and PowerShell scripting to manage and troubleshoot issues across a vast network of over 1000 machines."
      ]
    },
    {
      company: "United States Army Reserve",
      location: "Fort Lewis, WA",
      title: "System Administrator",
      range: "08/2016 to Present",
      responsibilities: [
        "Managed and maintained a robust network infrastructure supporting the United States Army Reserve, optimizing servers across multiple geographic locations.",
        "Spearheaded user management and group policy implementation within Active Directory, ensuring compliance with stringent security protocols.",
        "Conducted regular software and system patching, ensuring system integrity and compliance with regulatory requirements.",
        "Collaborated with application support teams to optimize system performance, reliability, and availability."
      ]
    },
    {
      company: "Terra Staffing",
      location: "Seattle, WA",
      title: "Linux System Administrator",
      range: "12/2015 to 08/2016",
      responsibilities: [
        "Developed and maintained installation, configuration, and security procedures for Linux-based servers.",
        "Applied regular OS/application patches and upgrades, ensuring peak performance and security.",
        "Monitored virtual environments, servers, and networks, proactively addressing issues to ensure uninterrupted service delivery.",
        "Configured essential network services, enabling seamless communication and data accessibility within multi-platform LAN environments."
      ]
    }
  ];

  useEffect(() => {
    if (tabs.current[tabFocus]) {
      tabs.current[tabFocus].focus();
    }
  }, [tabFocus]);

  const onKeyDown = e => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
      const newFocus = tabFocus + (e.keyCode === 40 ? 1 : -1);
      if (newFocus >= 0 && newFocus < experiences.length) {
        setTabFocus(newFocus);
      }
    }
  };

  return (
    <section className="experience-container" id="experience">
      <h1>Professional Experience</h1>
      <div className="tabs-container">
        <ul className="tab-list" role="tablist" aria-label="Job tabs" onKeyDown={onKeyDown}>
          {experiences.map((experience, i) => (
            <li key={i}>
              <button
                className={`tab-button ${activeTabId === i ? 'active' : ''}`}
                onClick={() => setActiveTabId(i)}
                ref={el => (tabs.current[i] = el)}
                id={`tab-${i}`}
                role="tab"
                aria-selected={activeTabId === i}
                aria-controls={`panel-${i}`}
                tabIndex={activeTabId === i ? 0 : -1}
              >
                <span>{experience.company}</span>
              </button>
            </li>
          ))}
          <span
            className="tab-highlight"
            style={{
              transform: `${window.innerWidth <= 768
                ? `translateX(${activeTabId * 120}px)`
                : `translateY(${activeTabId * 42}px)`}`
            }}
          />
        </ul>

        {experiences.map((experience, i) => (
          <div
            key={i}
            className="tab-content"
            id={`panel-${i}`}
            role="tabpanel"
            aria-labelledby={`tab-${i}`}
            tabIndex={activeTabId === i ? 0 : -1}
            hidden={activeTabId !== i}
          >
            <h4 className="job-title">
              <span>{experience.title}</span>
              <span className="company">
                <span>&nbsp;@&nbsp;</span>
                <span>{experience.company}</span>
              </span>
            </h4>
            <h5 className="job-details">
              <span>{experience.range}</span>
              <span>&nbsp;|&nbsp;</span>
              <span>{experience.location}</span>
            </h5>
            <ul className="responsibilities-list">
              {experience.responsibilities.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProfessionalExperience;